import Alarmas from "./pages/seguridad";

const routes = [
  {
    route: "/alarmas",
    component: <Alarmas />,
  },
];

export default routes;
