import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#E30613",
      contrastText: "#fff",
    },
    secondary: {
      main: "#fff",
      contrastText: "#E30613",
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        fontFamily: "'Montserrat', sans-serif",
        fontWeight: 500,
      },
    },
    MuiTextField: {
      variants: [
        {
          props: { className: "contained" },
          style: {
            "& .MuiFilledInput-input": {
              color: "black!important",
              opacity: "0.60!important",
              fontWeight: "600!important",
              padding: ".7rem!important",
              alignItems: "center",
              "&::placeholder": {
                color: "black!important",
                opacity: "0.40",
              },
            },
            "& .MuiFilledInput-root": {
              "&:before": {
                borderBottom: "0!important",
              },
              "&:after": {
                borderBottom: "0!important",
              },
              backgroundColor: "#dedede!important",
              borderRadius: "25px!important",
              boxShadow: "none",
            },
            "&. MuiInputAdornment-root": {
              marginTop: "0!important",
            },
          },
        },
      ],
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: "'Montserrat', sans-serif",
          fontWeight: 600,
          borderRadius: "25px",
          fontSize: "1rem",
          padding: "6px 40px",
          textTransform: "none",
        },
      },
    },
  },
});

export default theme;
