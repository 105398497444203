import { Box, Button, Container, Stack, Typography } from "@mui/material";
import Carousel from "react-bootstrap/Carousel";

import HeaderAlarmas from "../../components/HeaderAlarmas";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { CarouselItem } from "react-bootstrap";

const Section1 = () => {
  const carousel = ["/assets/pc/slider1.webp", "/assets/pc/slider2.webp"];

  return (
    <>
      <HeaderAlarmas />
      <Box>
        <Box
          sx={{
            position: "relative",
            zIndex: "2",
            backgroundColor: {xs: "#00000070", sm: "transparent"}, 
          }}
        >
          <Container>
            <Stack
              sx={{
                height: "100vh",
                alignItems: { xs: "center", sm: "start" },
                justifyContent: "center",
                gap: "10px",
                width: { xs: "100%", sm: "50%" },
                position: "relative",
                zIndex: "3",
              }}
            >
              <Typography
                variant="h3"
                color="#fff"
                fontWeight={600}
                textAlign={{ xs: "center", sm: "start" }}
              >
                SISTEMA DE ALARMAS Y MONITOREOS
              </Typography>
              <Typography variant="h4" color="#fff" fontWeight="400">
                
              </Typography>
              <Stack
                sx={{
                  color: "#fff",
                  textAlign: { xs: "center", sm: "start" },
                  gap: "10px",
                  alignItems: { xs: "center", sm: "flex-start" },
                }}
                p="10px"
              >
                <Typography
                  variant="h4"
                  fontWeight={600}
                  sx={{ fontSize: { sm: "1.5rem" } }}
                  mt={2}
                >
                  ¡1ER MES GRATUITO EN LA RENTA <br /> DEL SERVICIO!
                </Typography>
                <Stack
                  sx={{
                    alignItems: { xs: "center", sm: "start" },
                    width: { xs: "100%" },
                    mt: 2,
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      padding: "6px 20px",
                      color: "rgba(227,6,19,1)",
                      backgroundColor: "#fff",
                      border: "1px solid rgba(227,6,19,1)",
                      borderRadius: "15px",
                      ":hover": {
                        backgroundColor: "#fff",
                        boxShadow: "10px 10px 67px -19px #fff",
                      },
                    }}
                    endIcon={<WhatsAppIcon />}
                    onClick={() => openLinks()}
                  >
                    Solicita información
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          </Container>
        </Box>
        <Carousel
          controls={false}
          style={{
            position: "absolute",
            zIndex: "0",
            top: "0",
            bottom: "0",
            height: "100vh",
            width: "100%",
          }}
        >
          {carousel.map((item, i) => (
            <Carousel.Item key={i}>
              <img
                src={item}
                width="100%"
                style={{ objectFit: "cover", height: "100vh" }}
              />
            </Carousel.Item>
          ))}
        </Carousel>
      </Box>
    </>
  );

  function openLinks() {
    window.open("https://wa.me/5634125295");
  }
};

export default Section1;
