import Footer from "../../components/Footer"
import Section1 from "./section1"
import Section2 from "./section2"

const Alarmas = () => {
  return (
    <>
      <Section1 />
      <Section2 />
      <Footer />
    </>
  )
}

export default Alarmas

