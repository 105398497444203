import { AppBar, Box, Stack, Toolbar } from "@mui/material";
import { Link } from "react-router-dom";

const HeaderAlarmas = () => {
  var opciones = [
    {
      title: "BLOG",
      route: "https://www.corporativoultra.com/blog/",
    },
    {
      title: "INICIO",
      route: "https://corporativoultra.com/",
    },
    {
      title: "ANÁLISIS R.O.C.A",
      route: "https://corporativoultra.com/analisis-r-o-c-a/",
    },
  ];

  return (
    <>
      <Box sx={{ flexGrow: 1, position: "relative", zIndex: "3" }}>
        <AppBar
          position="static"
          sx={{
            backgroundColor: "transparent",
            boxShadow: "none",
            position: "absolute",
            pt: "10px",
          }}
        >
          <Toolbar>
            <Stack
              direction="row"
              sx={{
                justifyContent: { xs: "center", sm: "flex-end" },
                alignItems: {sm: "center"},
                width: "100%",
                gap: "20px",
              }}
            >
              <Stack sx={{flexGrow: "1", display: {xs: "none", sm: "flex"}}}>
                <img src="/assets/mov/LOGOBLANCOMOVIL.webp" width="100px" />
              </Stack>
              {opciones.map((item, i) => (
                <Link to={item.route} key={i} style={{ fontWeight: "600" }}>
                  {item.title}
                </Link>
              ))}
            </Stack>
          </Toolbar>
        </AppBar>
      </Box>
    </>
  );
};

export default HeaderAlarmas;
