import {
  Box,
  Container,
  Stack,
  Typography,
  TextField,
  Button,
} from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

const Section2 = () => {
  const cards = [
    {
      title: "CUIDAMOS TU HOGAR 24/7",
      image: "/assets/pc/ICONO2PC.webp",
    },
    {
      title: "PUERTAS Y VENTANAS MONITOREADAS",
      image: "/assets/pc/ICONO1PC.webp",
    },
    {
      title: "ASISTENCIA EN EMERGENCIAS MÉDICAS.",
      image: "/assets/pc/ICONO3PC.webp",
    },
  ];

  return (
    <>
      <Box>
        <Container>
          <Stack py={6}>
            <Stack>
              <Typography
                variant="h5"
                color="#e30613"
                textAlign="center"
                sx={{ fontWeight: "600" }}
              >
                CONSIGUE HOY MISMO UN SISTEMA DE ALARMAS PARA TU HOGAR.
              </Typography>
              <Stack
                spacing={2}
                mt={2}
                direction={{ md: "row" }}
                justifyContent={{ md: "center" }}
                sx={{ gap: { xs: "10px", md: "0" } }}
              >
                <TextField
                  className="contained"
                  variant="filled"
                  placeholder="Nombre"
                ></TextField>
                <TextField
                  className="contained"
                  variant="filled"
                  placeholder="Telefono"
                ></TextField>
                <TextField
                  className="contained"
                  variant="filled"
                  placeholder="Correo"
                ></TextField>
                <Stack>
                  <Button
                    variant="contained"
                    sx={{
                      background:
                        "linear-gradient(90deg, rgba(227,6,19,1) 0%, rgba(227,6,19,0.8519782913165266) 100%)",
                    }}
                  >
                    ¡Obtenlo ya!
                  </Button>
                </Stack>
              </Stack>
              <Stack spacing={1}>
                <Typography
                  variant="h4"
                  color="#e30613"
                  textAlign="center"
                  fontWeight={800}
                  mt={4}
                >
                  ALARMAS Y MONITOREO PARA EL HOGAR
                </Typography>
                <Typography
                  variant="h5"
                  color="#e30613"
                  textAlign="center"
                  fontWeight={600}
                  mt={4}
                >
                  
                </Typography>
              </Stack>
              {/* cards */}
              <Stack
                direction="row"
                sx={{ flexWrap: "wrap", gap: "35px", justifyContent: "center" }}
                mt={5}
              >
                {cards.map((item, i) => (
                  <Stack
                    sx={{
                      px: "30px",
                      width: "300px",
                      height: "300px",
                      justifyContent: "space-Between",
                      boxSizing: "border-box",
                      borderRadius: "20px",
                      border: "1px solid #dedede",
                      boxShadow: "10px 10px 67px -19px rgba(0,0,0,0.31)",
                      ":hover": {
                        boxShadow: "10px 10px 67px -19px rgba(227,6,19,0.7)",
                        cursor: "pointer",
                        borderColor: "rgba(227,6,19,.8)",
                        animation: "scaleHov .2s linear",
                      },
                      "@keyFrames scaleHov": {
                        "0%": {
                          transform: "scale(1)",
                        },
                        "50%": {
                          transform: "scale(1.02)",
                        },
                        "100%": {
                          transform: "scale(1)",
                        },
                      },
                    }}
                    key={i}
                  >
                    <img
                      src="/assets/pc/ESCUDITO.webp"
                      style={{
                        width: "30px",
                        marginLeft: "-45px",
                        marginTop: "-10px",
                      }}
                    />
                    <Stack sx={{ alignItems: "center", gap: "10px" }}>
                      <img src={item.image} />
                      <Typography
                        variant="h6"
                        color="#a1a1a1"
                        sx={{ fontWeight: "600", textAlign: "center" }}
                      >
                        {item.title}
                      </Typography>
                    </Stack>
                    <Stack alignItems="end">
                      <Button
                        variant="contained"
                        sx={{
                          marginBottom: "-20px",
                          marginRight: "-60px",
                          padding: "6px 20px",
                          background:
                            "linear-gradient(90deg, rgba(255,47,59,1) 0%, rgba(227,6,19,1) 34%, rgba(227,6,19,1) 100%)",
                        }}
                        endIcon={<WhatsAppIcon />}
                        onClick={() => openLinks()}
                      >
                        Solicita información
                      </Button>
                    </Stack>
                  </Stack>
                ))}
              </Stack>
              {/* escudo rojo */}
              <Stack
                direction={{ md: "row-reverse" }}
                sx={{ gap: { md: "30px" } }}
                my={8}
              >
                <Stack justifyContent={{ md: "center" }}>
                  <Typography
                    variant="h5"
                    textAlign={{ xs: "center", md: "start" }}
                  >
                    Nos esforzamos por proporcionar un servicio de alta calidad
                    que brinda tranquilidad y confianza a nuestros clientes.
                  </Typography>
                  <Typography
                    variant="p"
                    mt={2}
                    textAlign={{ xs: "center", md: "start" }}
                    color="#a1a1a1"
                  >
                    Como proveedores de sistemas de alarma, entendemos que cada
                    hogar y negocio son únicos, por lo que ofrecemos soluciones
                    de seguridad personalizadas que se adaptan a tus necesidades
                    específicas.
                  </Typography>
                </Stack>
                <Stack alignItems="center" mt={{ xs: 3, md: 0 }}>
                  <img src="/assets/pc/ESCUDOROJOPC.webp" />
                </Stack>
              </Stack>
              {/* seccion 3 */}
              <Stack direction={{ md: "row" }} alignItems={{ md: "center" }}>
                <Stack sx={{ gap: { md: "30px" } }}>
                  {/* Telefono */}
                  <Stack>
                    <Typography
                      variant="h5"
                      mt={3}
                      textAlign={{ xs: "center", md: "start" }}
                    >
                      Disponibilidad constante y dedicada de nuestros
                      especialistas, quienes están listos para atender tus
                      necesidades de seguridad en cualquier momento del día o de
                      la noche.
                    </Typography>
                    <Stack
                      alignItems="center"
                      mt={3}
                      sx={{ display: { md: "none" } }}
                    >
                      <img
                        src="/assets/mov/CELULARMOVIL.webp"
                        style={{ width: "100%" }}
                      />
                    </Stack>
                    <Typography
                      variant="p"
                      mt={3}
                      textAlign="center"
                      color="#a1a1a1"
                      sx={{ display: { md: "none" } }}
                    >
                      Facilidad para conectarte a tus cámaras de seguridad,
                      permitiéndote ver y registrar sucesos en tiempo real desde
                      tu dispositivo móvil, dándote la capacidad de estar al
                      tanto de loque sucede en tu propiedad en cualquier
                      instante
                    </Typography>
                  </Stack>
                  {/* button */}
                  <Stack
                    alignItems="center"
                    mt={3}
                    sx={{ display: { md: "none" } }}
                  >
                    <Button
                      variant="contained"
                      sx={{
                        padding: "20px 40px",
                        borderRadius: "10px",
                        fontSize: "20px",
                        background:
                          "linear-gradient(90deg, rgba(255,47,59,1) 0%, rgba(227,6,19,1) 34%, rgba(227,6,19,1) 100%)",
                      }}
                      endIcon={<WhatsAppIcon />}
                      onClick={() => openLinks()}
                    >
                      Solicita información
                    </Button>
                  </Stack>
                  {/* info */}
                  <Stack
                    direction={{ md: "row" }}
                    sx={{ gap: { md: "20px" } }}
                    mt={{ md: 3 }}
                  >
                    <Stack
                      justifyContent={{ md: "center" }}
                      sx={{ display: { xs: "none", md: "inherit" } }}
                    >
                      <img src="/assets/pc/MUNDOPC.webp" />
                    </Stack>
                    <Stack>
                      <Typography
                        variant="h5"
                        mt={{ xs: 3, md: 0 }}
                        textAlign={{ xs: "center", md: "start" }}
                      >
                        SUPERVISIÓN Y GESTIÓN DE TU RESIDENCIA O NEGOCIO DE
                        MANERA REMOTA
                      </Typography>
                      <Typography
                        variant="p"
                        mt={{ xs: 3, md: 0 }}
                        textAlign={{ xs: "center", md: "start" }}
                        color="#a1a1a1"
                      >
                        Usamos las últimas innovaciones en tecnología de
                        seguridad, garantizando la tranquilidad de tener todo
                        bajo control incluso cuando estás lejos.
                      </Typography>
                    </Stack>
                  </Stack>
                  {/* button en md */}
                  <Stack
                    alignItems="center"
                    mt={3}
                    sx={{ display: { xs: "none", md: "inherit" } }}
                  >
                    <Button
                      variant="contained"
                      sx={{
                        padding: "20px",
                        borderRadius: "10px",
                        background:
                          "linear-gradient(90deg, rgba(255,47,59,1) 0%, rgba(227,6,19,1) 34%, rgba(227,6,19,1) 100%)",
                      }}
                      onClick={() => openLinks()}
                    >
                      <Stack>
                        <Typography variant="p" textAlign="start">
                          Facilidad para conectarte a tus cámaras de seguridad,
                          permitiéndote ver y registrar sucesos en tiempo real
                          desde tu dispositivo móvil, dándote la capacidad de
                          estar al tanto de loque sucede en tu propiedad en
                          cualquier instante.
                        </Typography>
                        <Stack
                          direction="row"
                          sx={{
                            gap: "10px",
                            alignItems: "center",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Typography variant="p" fontSize="14px">
                            Solicita información
                          </Typography>
                          <WhatsAppIcon />
                        </Stack>
                      </Stack>
                    </Button>
                  </Stack>
                  <Typography
                    variant="p"
                    mt={4}
                    textAlign={{ xs: "center", md: "start" }}
                    color="#a1a1a1"
                    sx={{ display: { xs: "none", md: "inherit" } }}
                  >
                    Ya sea que estés buscando proteger tu hogar de posibles
                    intrusos o asegurar la seguridad de tu empresa, tenemos la
                    tecnología y el equipo para ayudarte a lograrlo.
                  </Typography>
                </Stack>
                <Stack
                  direction={{ md: "row" }}
                  sx={{
                    display: { xs: "none", md: "inherit" },
                    alignItems: { md: "center" },
                  }}
                >
                  <img src="/assets/pc/CELULARPC.webp" height="100%" />
                </Stack>
              </Stack>
            </Stack>
            {/* Text inputs */}
            <Stack
              spacing={2}
              mt={4}
              direction={{ md: "row" }}
              justifyContent={{ md: "center" }}
              sx={{ gap: { xs: "10px", md: "0" } }}
            >
              <TextField
                className="contained"
                variant="filled"
                placeholder="Nombre"
              ></TextField>
              <TextField
                className="contained"
                variant="filled"
                placeholder="Telefono"
              ></TextField>
              <TextField
                className="contained"
                variant="filled"
                placeholder="Correo"
              ></TextField>
              <Stack>
                <Button
                  variant="contained"
                  sx={{
                    background:
                      "linear-gradient(90deg, rgba(227,6,19,1) 0%, rgba(227,6,19,0.8519782913165266) 100%)",
                  }}
                >
                  ¡Obtenlo ya!
                </Button>
              </Stack>
            </Stack>
            <Typography
              variant="p"
              mt={4}
              textAlign={"center"}
              color="#a1a1a1"
              sx={{ display: { xs: "inherit", sm: "none" } }}
            >
              Ya sea que estés buscando proteger tu hogar de posibles intrusos o
              asegurar la seguridad de tu empresa, tenemos la tecnología y el
              equipo para ayudarte a lograrlo.
            </Typography>
          </Stack>
        </Container>
      </Box>
    </>
  );

  function openLinks() {
    window.open("https://wa.me/5634125295");
  }
};

export default Section2;
