import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import LocationOnIcon from "@mui/icons-material/LocationOn";

export default function Footer() {
  return (
    <Box sx={{ backgroundColor: "primary.main" }}>
      <Stack p={5}>
        <Grid
          container
          sx={{ color: "white!important" }}
          spacing={2}
          alignItems="start"
          justifyContent={{ xs: "center", md: "space-between" }}
        >
          <Grid item xs={12} sm={6} md={3} order={1}>
            <Stack alignItems="center">
              <Typography variant="h5">Contáctanos</Typography>
              <Typography mt={1}>Oficina:</Typography>
              <Typography>55 - 4777 - 0300</Typography>
              <Typography mt={1}>Correo Electrónico:</Typography>
              <Typography>ventas@corporativoultra.com</Typography>
            </Stack>
          </Grid>

          <Grid item xs={12} md={3} order={{ xs: 3, md: 2 }}>
            <Stack
              alignItems="center"
              sx={{ typography: { textAlign: "center" } }}
            >
              <Typography variant="h5">Nosotros</Typography>
              <Typography variant="p">
                Somos orgullosamente mexicanos, contamos con el respaldo de más
                de 36 años de experiencia y somos la empresa de seguridad
                privada con mayor presencia en todo México.
              </Typography>
              <Typography
                mt={1}
                variant="p"
                sx={{ textDecoration: "underline" }}
                component={Link}
                to="https://www.corporativoultra.com/aviso-de-privacidad/"
              >
                Aviso de privacidad
              </Typography>
              <Typography
                mt={1}
                variant="p"
                sx={{ textDecoration: "underline" }}
                component={Link}
                to="https://www.corporativoultra.com/politica-de-sgcs/"
              >
                Política de SGCS
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={3} order={{ xs: 2, md: 3 }}>
            <Stack
              alignItems="center"
              sx={{ typography: { textAlign: "center" } }}
            >
              <Typography variant="h5">Ubicación</Typography>
              <Typography mt={1}>
                Ing. Militares No. 36 Col. Lomas de Sotelo
              </Typography>
              <Button
                sx={{ mt: 1 }}
                variant="contained"
                color="secondary"
                LinkComponent={Link}
                to="https://www.google.com/maps?ll=19.454498,-99.217069&z=18&t=m&hl=es&gl=MX&mapclient=embed&q=Rtno.+2+Ingenieros+Militares+36+Residencial+Lomas+de+Sotelo+53390+Naucalpan+de+Ju%C3%A1rez,+M%C3%A9x."
              >
                <LocationOnIcon />
                ¿Cómo llegar?
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={12} md={3} order={4}>
            <Stack
              alignItems="center"
              sx={{ typography: { textAlign: "center" } }}
            >
              <Typography variant="h5">Redes sociales</Typography>
              <Stack direction="row" spacing={2}>
                <Link
                  to="https://www.facebook.com/CorporativoUltraOficial/?locale=es_LA"
                  target="_blank"
                >
                  <img src="/assets/facebook.webp" alt="Facebook" />
                </Link>
                <Link
                  to="https://www.instagram.com/corporativoultra/?hl=es"
                  target="_blank"
                >
                  <img src="/assets/instagram.webp" alt="Instagram" />
                </Link>
                <Link
                  to="https://www.linkedin.com/company/corporativo-ultra/"
                  target="_blank"
                >
                  <img src="/assets/linkedin.webp" alt="Linkedin" />
                </Link>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    </Box>
  );
}
